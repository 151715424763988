<template>
  <div 
    class="wrap-table border-table"
  >
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <h4>Client Information</h4>
      <button
        v-if="isHidden"
        class="caret"
        @click="isHidden = !isHidden"
      >
        ˅
      </button>
      <button
        v-if="!isHidden"
        class="caret"
        @click="isHidden = !isHidden"
      >
        ˄
      </button>
    </div>
    <div class="row row-group">
      <div class="col-sm-4">
        <span class="text-up">Client Name</span>
      </div>
      <div class="col-sm-8">
        <div 
          v-if="edit.name.edit == false" 
          class="row-large name_patient"
        >
          <div class="col-sm-10">
            <span>{{ patient.first_name }} {{ patient.last_name }}</span>
          </div>
          <div class="col-sm-2 action-box">
            <a 
              class="link_action hover-btn" 
              @click.prevent="editInfo('name')"
            >
              Edit</a>
          </div>
        </div>
        <div 
          v-else 
          class="col-sm-12 form_name pr-0"
        >
          <ValidationObserver 
            ref="name" 
            v-slot="{ invalid }" 
            tag="form"
          >
            <div class="row">
              <div class="col-6">
                <validation-provider 
                  v-slot="{ errors }" 
                  name="First Name" 
                  rules="required"
                >
                  <input 
                    v-model="patient.first_name"
                    type="text" 
                    name="first_name" 
                    placeholder="First Name"
                    :class="errors.length ? 'error' : ''"
                  >
                  <p class="validation error mt-2">
                    {{ errors[0] }}
                  </p>
                </validation-provider>
              </div>
              <div class="col-6">
                <validation-provider 
                  v-slot="{ errors }" 
                  name="Last Name" 
                  rules="required"
                >
                  <input 
                    v-model="patient.last_name"
                    type="text" 
                    name="last_name" 
                    placeholder="Last Name"
                    :class="errors.length ? 'error' : ''"
                  >
                  <p class="validation error mt-2">
                    {{ errors[0] }}
                  </p>
                </validation-provider>
              </div>
            </div>
            <div class="text-right pt-3">
              <a 
                class="btn btn-bordered mr-2"
                @click="cancelEdit('name')"
              >Cancel</a>
              <a 
                class="btn btn-primary autowidth"
                @click="saveInfo('name')"
                :class="{ disabled: invalid }"
              >Save</a>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div 
      class="row row-group"
    >
      <div class="col-sm-4">
        <span class="text-up">Skincare Pro</span>
      </div>
      <div class="col-sm-8">
        <div 
          v-if="edit.physician.edit == false"
          class="row-large physician"
        >
          <div class="col-sm-10">
            <span>{{ patient.physician ? patient.physician.name : 'None' }}</span>
          </div>
          <div class="col-sm-2 action-box">
            <a 
              class="link_action hover-btn" 
              @click="editInfo('physician')"
            >Edit</a>
          </div>
        </div>
        <div 
          v-else
          class="col-sm-12 form_physician pr-0"
        >
          <ValidationObserver 
            ref="physician" 
            v-slot="{ invalid }" 
            tag="form"
          >
            <validation-provider 
              v-if="isRole('super_admin')"
              v-slot="{ errors }" 
              name="Practice" 
              rules="required"
            >
              <SelectPractice 
                v-model="practiceId"
                :title="currentPracticeName()"
                :items="valuesForSelect(practices)"
                :total="practicesTotal"
                :loading="isPracticesLoading"
                :name="`practice-select-view-pat`"
                :filters="filters"
                @load-more="loadMorePractices"
              />
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
            </validation-provider>
            <validation-provider 
              v-slot="{ errors }" 
              name="Physician" 
              rules="required"
            >
              <select-physican
                v-model="patient.user_id"
                title="All Skincare Pros"
                :items="valuesForSelect(physicians)"
                :total="physiciansTotal"
                :loading="isPhysiciansLoading"
                :name="'patinet-select'"
                :filters="filters"
                @load-more="loadMorePhysicians"
              />
              <p class="validation error mt-2">
                {{ errors[0] }}
              </p>
            </validation-provider>
            <div class="text-right pt-3">
              <a 
                class="btn btn-bordered mr-2"
                @click="cancelEdit('physician')"
              >Cancel</a>
              <a 
                class="btn btn-primary autowidth"
                @click="saveInfo('physician')"
                :class="{ disabled: invalid }"
              >Save</a>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div class="row row-group">
      <div class="col-sm-4">
        <span class="text-up">Email Address</span>
      </div>
      <div class="col-sm-8">
        <div 
          v-if="edit.email.edit == false"
          class="row-large email_edit"
        >
          <div class="col-sm-10">
            <span>{{ patient.email }}</span>
          </div>
          <div class="col-sm-2 action-box">
            <a class="link_action hover-btn" @click="editInfo('email')">Edit</a>
          </div>
        </div>
        <div 
          v-else
          class="col-sm-12 form_email pr-0"
        >
          <ValidationObserver 
            ref="email" 
            v-slot="{ invalid }" 
            tag="form"
          >
            <validation-provider 
              v-slot="{ errors }" 
              name="Email" 
              rules="email|required"
            >
              <input 
                v-model="patient.email" 
                type="email" 
                name="email" 
                :class="errors.length ? 'error' : ''"
                @input="enterEmail" 
              >
              <p 
                v-for="(error, errorKey) in emailErrors"
                :key="errorKey"
                class="validation error mt-2"
              >
                {{ error }}
              </p>
              <p 
                v-for="(err, errKey) in errors"
                :key="errKey"
                class="validation error mt-2"
              >
                {{ err }}
              </p>
            </validation-provider>
            <div class="text-right pt-3">
              <a 
                class="btn btn-bordered mr-2"
                @click="cancelEdit('email')"
              >Cancel</a>
              <a
                class="btn btn-primary autowidth"
                @click="saveInfo('email')"
                :class="{ disabled: invalid }"
              >Save</a>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div
      v-if="!isHidden"
      style="margin-top: 15px"
    >
      <div class="row row-group">
        <div class="col-sm-4">
          <span class="text-up">Phone Number</span>
        </div>
        <div class="col-sm-8">
          <div
            v-if="edit.phone.edit == false"
            class="row-large phone_edit"
          >
            <div class="col-sm-10">
              <span>{{ patient.phone ? patient.phone : 'None' }}</span>
            </div>
            <div class="col-sm-2 action-box">
              <a
                class="link_action hover-btn"
                @click="editInfo('phone')"
              >Edit</a>
            </div>
          </div>
          <div 
            v-else
            class="col-sm-12 form_phone pr-0"
          >
            <input
              v-model="patient.phone"
              type="tel"
              name="phone"
              maxlength="16"
              placeholder="(555) 555 - 5555"
              @input="enterNumber(phoneTypes.patient)"
              @change="changeNumber(phoneTypes.patient)"
            >
            <p 
              v-for="(phoneErr, phoneKey) in phoneErrors" 
              :key="phoneKey"
              class="validation error mt-2"
            >
              {{ phoneErr }}
            </p>
            <div class="text-right pt-3">
              <a 
                class="btn btn-bordered mr-2"
                @click="cancelEdit('phone')" 
              >Cancel</a>
              <a 
                class="btn btn-primary autowidth"
                @click="saveInfo('phone')"
                :class="{ disabled: phoneErrors.length > 0 }" 
              >Save</a>
            </div>
          </div>
        </div>
      </div>
      <div 
        v-if="patient.shipping_address"
        class="row row-group"
      >
        <div class="col-sm-4">
          <span class="text-up">Shipping Address</span>
        </div>
        <div class="col-sm-8">
          <div 
            v-if="edit.shipping_address.edit == false"
            class="row-large adresses_edit"
          >
            <div class="col-sm-10">
              <span>{{ bulidAddressStr(patient.shipping_address) }}</span>
            </div>
            <div class="col-sm-2 action-box">
              <a 
                class="link_action hover-btn" 
                @click="editInfo('shipping_address')"
              >Edit</a>
            </div>
          </div>
          <div 
            v-else
            class="col-sm-12 form_adresses pr-0"
          >
            <ValidationObserver 
              ref="shipping_address" 
              v-slot="{ invalid }" 
              tag="form"
            >
              <div class="row ml-0">
                <div class="col-sm-6 form-group">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="Shipping first name" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="sh_f_name">First Name</label>
                    <input 
                      id="sh_f_name" 
                      v-model="patient.shipping_address.first_name"
                      type="text" 
                      :class="errors.length ? 'error' : ''"
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
                <div class="col-sm-6 form-group">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="Shipping last name" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="sh_l_name">Last Name</label>
                    <input 
                      id="sh_l_name" 
                      v-model="patient.shipping_address.last_name"
                      type="text" 
                      :class="errors.length ? 'error' : ''"
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group">
                <validation-provider 
                  v-slot="{ errors }" 
                  name="Street Address" 
                  rules="required"
                  class="form-group"
                >
                  <label for="street">Street address</label>
                  <input
                    id="street"
                    v-model="patient.shipping_address.address1"
                    placeholder="113 Main Stret"
                    type="text"
                    name="street"
                    :class="errors.length ? 'error' : ''"
                  >
                  <p 
                    v-for="(err, errKey) in errors"
                    :key="errKey"
                    class="validation error mt-2"
                  >
                    {{ err }}
                  </p>
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="suite1">Unit or suite number (optional)</label>
                <input 
                  id="suite1" 
                  v-model="patient.shipping_address.address2"
                  placeholder="Suite 600" 
                  type="text" 
                  name="suite1" 
                >
              </div>
              <div class="row ml-0 mb-3">
                <div class="form-group col-sm-6">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="City" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="city">City</label>
                    <input
                      id="city"
                      v-model="patient.shipping_address.city"
                      placeholder="Chicago"
                      type="text"
                      name="city"
                      :class="errors.length ? 'error' : ''"
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-group col-sm-6">
                  <label for="state1">State</label>
                  <StateSelect
                    id="state1"
                    v-model="patient.shipping_address.state" 
                    class="select-lg col"
                    @change="validZip('shipping_address')" 
                  />
                </div>
                <div class="form-group col-sm-6">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="Zip code" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="zip1">Zip code</label>
                    <input
                      id="zip1"
                      v-model="patient.shipping_address.zip"
                      placeholder="12345"
                      type="text"
                      name="zip1"
                      :class="errors.length || shippingZipMessage ? 'error' : ''"
                      @input="validZip('shipping_address')" 
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                    <p 
                      v-if="shippingZipMessage"
                      class="validation error mt-2" 
                    >
                      {{ shippingZipMessage }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-group col-sm-6">
                  <label for="country1">Country</label>
                  <input
                    id="country1"
                    placeholder="United States"
                    readonly
                    value="United States"
                    type="text"
                    name="ext"
                  >
                </div>
                <div class="form-group col-sm-6 mb-0">
                  <div class="head-tooltip form-group">
                    <label for="phonen">
                      Phone Number
                      <i
                        class="fas fa-question-circle"
                        @mouseover="hover('sa-phone')" 
                        @mouseleave="hoverOff('sa-phone')" 
                      />
                    </label>
                    <div 
                      data-tooltip-type="sa-phone" 
                      class="tooltip-black"
                    >
                      Phone numbers are used by delivery drivers to reach out if they have delivery issues.
                    </div>
                  </div>
                  <input
                    id="phonen"
                    v-model="patient.shipping_address.phone"
                    placeholder="(555) 555 - 5555"
                    type="tel"
                    name="phonen"
                    @input="enterNumber(phoneTypes.shipping)"
                    @change="changeNumber(phoneTypes.shipping)"
                  >
                  <p 
                    v-for="(err, errKey) in shippingPhoneErrors"
                    :key="errKey"
                    class="validation error mt-2"
                  >
                    {{ err }}
                  </p>
                </div>
              </div>

              <div class="text-right">
                <a 
                  class="btn btn-bordered mr-2"
                  @click="cancelEdit('shipping_address')" 
                >Cancel</a>
                <a 
                  class="btn btn-primary autowidth"
                  @click="saveShipping" 
                  :class="{ disabled: invalid }"
                >Save</a>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="row row-group">
        <div class="col-sm-4">
          <span class="text-up">Payment Methods</span>
        </div>
        <div class="col-sm-8">
          <div 
            v-if="edit.paymentMethods.edit == false" 
            class="row-large pay_edit"
          >
            <div class="col-sm-10">
              <span v-if="patient.payment_method && patient.payment_method.card_number">
                {{ patient.payment_method.card_type }} ending in {{ patient.payment_method.card_number }}
              </span>
              <span v-else>None</span>
            </div>
            <div class="col-sm-2 action-box">
              <a 
                class="link_action hover-btn" 
                @click="editInfo('paymentMethods')"
              >Edit</a>
            </div>
          </div>
          <div 
            v-show="edit.paymentMethods.edit"
            class="col-sm-12 form_pay pr-0"
          >
            <div class="form-group">
              <label for="card">Card Number</label>
              <div id="card-element" />
            </div>
            <div class="row ml-0">
              <div class="form-group col-sm-6">
                <label for="exp">Expiration Date</label>
                <div id="card-expiry-element" />
              </div>
              <div class="form-group col-sm-6">
                <label for="security">Security Code</label>
                <div id="card-cvc-element" />
              </div>
            </div>
            <div class="text-right">
              <a 
                class="btn btn-bordered mr-2"
                @click="cancelEdit('paymentMethods')" 
              >Cancel</a>
              <a 
                class="btn btn-primary autowidth hover-btn"
                @click.prevent="savePayment"
              >Save</a>
            </div>
          </div>
        </div>
      </div>
      <div 
        v-if="patient.billing_address"
        class="row row-group"
      >
        <div class="col-sm-4">
          <span class="text-up">Billing Address</span>
        </div>
        <div class="col-sm-8">
          <div 
            v-if="edit.billing_address.edit == false"
            class="row-large adresses_edit"
          >
            <div class="col-sm-10">
              <span>{{ bulidAddressStr(patient.billing_address) }}</span>
            </div>
            <div class="col-sm-2 action-box">
              <a 
                class="link_action hover-btn" 
                @click="editInfo('billing_address')"
              >Edit</a>
            </div>
          </div>
          <div 
            v-else
          >
            <div class="row ml-0">
              <div class="form-group col-sm-12">
                <input 
                  id="bill_same" 
                  v-model="billingSameShipping"
                  type="checkbox" 
                >
                <label for="bill_same">Billing same as shipping address</label>
              </div>
            </div>
            <ValidationObserver
              ref="billing_address" 
              v-slot="{ invalid }" 
              tag="form"
            >
              <div class="row ml-0">
                <div class="col-sm-6 form-group">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="Shipping first name" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="sh_f_name">First Name</label>
                    <input 
                      id="sh_f_name" 
                      v-model="patient.billing_address.first_name"
                      type="text" 
                      :class="errors.length ? 'error' : ''"
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
                <div class="col-sm-6 form-group">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="Shipping last name" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="sh_l_name">Last Name</label>
                    <input 
                      id="sh_l_name" 
                      v-model="patient.billing_address.last_name"
                      type="text" 
                      :class="errors.length ? 'error' : ''"
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group">
                <validation-provider 
                  v-slot="{ errors }" 
                  name="Street Address" 
                  rules="required"
                  class="form-group"
                >
                  <label for="street">Street address</label>
                  <input
                    id="billing_street"
                    v-model="patient.billing_address.address1"
                    placeholder="113 Main Stret"
                    type="text"
                    name="street"
                    :class="errors.length ? 'error' : ''"
                  >
                  <p 
                    v-for="(err, errKey) in errors"
                    :key="errKey"
                    class="validation error mt-2"
                  >
                    {{ err }}
                  </p>
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="suite1">Unit or suite number (optional)</label>
                <input 
                  id="suite1" 
                  v-model="patient.billing_address.address2"
                  placeholder="Suite 600" 
                  type="text" 
                  name="suite1" 
                >
              </div>
              <div class="row ml-0">
                <div class="form-group col-sm-6">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="City" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="city">City</label>
                    <input
                      id="city"
                      v-model="patient.billing_address.city" 
                      placeholder="Chicago"
                      type="text"
                      name="city"
                      :class="errors.length ? 'error' : ''"
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2" 
                    >
                      {{ err }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-group col-sm-6">
                  <label for="state1">State</label>
                  <StateSelect
                    id="state2"
                    v-model="patient.billing_address.state"
                    class="select-lg col"
                    @change="validZip('billing_address')"
                  />
                </div>
                <div class="form-group col-sm-6">
                  <validation-provider 
                    v-slot="{ errors }" 
                    name="Zip code" 
                    rules="required"
                    class="form-group"
                  >
                    <label for="zip1">Zip code</label>
                    <input
                      id="zip1"
                      v-model="patient.billing_address.zip"
                      placeholder="12345"
                      type="text"
                      name="zip1"
                      :class="errors.length || billingZipMessage ? 'error' : ''"
                      @input="validZip('billing_address')" 
                    >
                    <p 
                      v-for="(err, errKey) in errors"
                      :key="errKey"
                      class="validation error mt-2"
                    >
                      {{ err }}
                    </p>
                    <p 
                      v-if="billingZipMessage"
                      class="validation error mt-2" 
                    >
                      {{ billingZipMessage }}
                    </p>
                  </validation-provider>
                </div>
                <div class="form-group col-sm-6">
                  <label for="country1">Country</label>
                  <input
                    id="country1"
                    placeholder="United States"
                    readonly
                    value="United States"
                    type="text"
                    name="ext"
                  >
                </div>
                <div class="form-group col-sm-6 mb-0">
                  <div class="head-tooltip form-group">
                    <label for="phonen">
                      Phone Number
                      <i 
                        class="fas fa-question-circle" 
                        @mouseover="hover('b-phone')" 
                        @mouseleave="hoverOff('b-phone')"
                      />
                    </label>
                    <div 
                      data-tooltip-type="b-phone" 
                      class="tooltip-black"
                    >
                      Phone numbers are used by delivery drivers to reach out if they have delivery issues.
                    </div>
                  </div>
                  <input
                    id="phonen"
                    v-model="patient.billing_address.phone"
                    placeholder="(555) 555 - 5555"
                    type="tel"
                    name="phonen"
                    @input="enterNumber(phoneTypes.billing)"
                    @change="changeNumber(phoneTypes.billing)"
                  >
                  <p 
                    v-for="(err, errKey) in billingPhoneErrors"
                    :key="errKey"
                    class="validation error mt-2"
                  >
                    {{ err }}
                  </p>
                </div>
              </div>
              <div class="text-right pt-3">
                <a 
                  class="btn btn-bordered mr-2"
                  @click="cancelEdit('billing_address')" 
                >Cancel</a>
                <a 
                  class="btn btn-primary autowidth"
                  @click="saveBilling"
                  :class="{ disabled: invalid }"
                >Save</a>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :show-modal="showModal" 
      modal-id="before_go"
      @hide-modal-window="showModal=false"
    >
      <img 
        :src="warning" 
        alt="Warning!" 
        class="warning-icon"
      >
      <h4 
        class="modal-title"
      >
        Before you go ...
      </h4>
      <p>You have unsaved changes. Are you sure you want to continue?</p>
      <div class="mb-3">
        <a 
          href="#" 
          class="btn btn-primary w-100"
          @click.prevent="continueWithoutSaving(modalCheck)" 
        >Continue Without Saving</a>
      </div>
      <div class>
        <a 
          href="#"
          class="btn btn-bordered w-100"
          data-dismiss="modal"
          @click.prevent="showModal = false"
        >Cancel</a>
      </div>
    </Modal>
    <div 
      v-if="showModal" 
      class="modal-backdrop fade show"
    />
  </div>
</template>

<style lang="scss">
  .patientview .content-down {
    width: 100%;
    margin-right: 0;
    .title {
      width: 100%;
    }
    .multi-select {
      width: 100%;
    }
    input[type="text"] {
      width: 100% !important;
    }
  }
  .caret {
    width: 30px;
    cursor:pointer;
    color:white;
    border: 0 none;
    border-radius: 2px;
    font-size: 0.935em;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    background-color: #a989de;
  }
  .caret:hover {
    background-color: #936bd6;
    border-color: #8c61d3;
  }
  button:focus {
    outline: none !important;
  }
</style>
<script src="https://www.w3schools.com/lib/w3.js"></script>

<script>
import warning from '@images/warning.png';
import {
  bulidAddressStr,
  validPhone
} from '@/helper';
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import { AsYouType } from 'libphonenumber-js';
import { mapGetters } from 'vuex';
import { isEqual } from 'lodash';
import Modal from '../partials/Modal';

// @group Patinet
import {
  required,
  email
} from 'vee-validate/dist/rules';
import {
  UPDATE_PATIENT,
  REASSIGN_PATIENT,
  UPDATE_PATIENT_ADDRESS,
  UPDATE_PATIENT_BILLING_ADDRESS,
  ADD_PATIENT_BILLING_ADDRESS,
  ADD_PATIENT_ADDRESS
} from '@/store/patients/actions.types';

import patientStateValidation from '@/mixins/patientStateValidation';
import StateSelect from '@/views/components/StateSelect';
import SelectPhysican from '@/views/components/Select';
import physician from '@/mixins/physician';
import SelectPractice from '@/views/components/Select';
import practices from '@/mixins/practices';
import stripe from '@/mixins/stripe';
import googleAutocompleate from '@/mixins/googleAutocompleate';

// Install rules
extend('required', required);
email['message'] = 'Please enter a valid email address';
extend('email', email);

// @group Patinet
export default {
  name: 'PatientViewinfo',
  components: {
    ValidationProvider, 
    ValidationObserver,
    Modal,
    StateSelect,
    SelectPhysican,
    SelectPractice,
  },

  mixins: [patientStateValidation, physician, stripe, googleAutocompleate, practices],

  props: {
    patient: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      isHidden: true,
      showModal: false,
      billingSameShipping: false,
      modalCheck: '',
      warning,
      shippingPhoneErrors: [],
      billingPhoneErrors: [],
      phoneErrors: [],
      emailErrors: [],
      filters: {},
      practiceId: '',
      loadOnInit: false,
      phoneTypes: {
        patient: 'patient',
        shipping: 'shipping',
        billing: 'billing',
      },
      edit: {
        name: {
          edit: false,
          first_name: '',
          last_name: '',
        },
        physician: {
          edit: false,
          user_id: null
        },
        email: {
          edit: false,
          value: '',
        },
        phone: {
          edit: false,
          value: '',
        },
        shipping_address: {
          edit: false,
          prevObj: {},
        },
        billing_address: {
          edit: false,
          prevObj: {},
        },
        paymentMethods: {
          edit: false,
          prevObj: {},
        },
      }
    };
  },

  beforeMount() {
    this.shippingComponent.forEach(el => {
      if (el.element == 'state')
        el.type.administrative_area_level_1 = 'long_name';
    })
  },

  watch: {
    billingSameShipping() {
      if (this.billingSameShipping)
        this.patient.billing_address = {...this.patient.billing_address, ...this.patient.shipping_address};
    },

    patient () {
      if (this.patient && !this.patient.phone) {
        this.patient.phone = this.getPhone();
      }

      if (this.patient.billing_address && !this.patient.billing_address.phone) {
        this.patient.billing_address.phone = this.getPhone();
      }

      if (this.patient.shipping_address && !this.patient.shipping_address.phone) {
        this.patient.shipping_address.phone = this.getPhone();
      }

      if (this.patient.practice) {
        this.practiceId = this.patient.practice.id;
      }
    },

    practiceId() {
      if(this.practiceId == this.patient.practice.id){
        this.patient.user_id = this.patient.physician.id;
      } else {
        this.patient.user_id = '';
      }
      this.loadPhysicansWithParctice();
    }
  },

  methods: {
    // @vuese
    // Used to validate phone
    // @arg Phone value
    validPhone,
    // @vuese
    // Used to convert address object to string, if object have empty  props
    // return word `None`
    // @arg address object
    bulidAddressStr,
    // @vuese
    // Used to fill phone in shipping, billing address and to patient object if
    // value empty
    getPhone() {
      let phone;
      if (this.patient && this.patient.phone)
        phone = this.patient.phone;
      if (this.patient.shipping_address && this.patient.shipping_address.phone)
        phone = this.patient.shipping_address.phone;
      if (this.patient.billing_address && this.patient.billing_address.phone)
        phone = this.patient.billing_address.phone;
      return phone;
    },
    // @vuese
    // Used to check if shipping form valid
    async shippingValid() {
      return await this.$refs.shipping_address.validate() &&
        this.shippingPhoneErrors.length == 0;
    },
    // @vuese
    // Used to save shipping
    async saveShipping() {
      const patientId = this.$route.params.id;
      if (await this.shippingValid()) {
        let handler = UPDATE_PATIENT_ADDRESS;
        if (
          this.patient.shipping_address.type &&
          this.patient.shipping_address.type == 'default'
        ) {
          handler = ADD_PATIENT_ADDRESS;
        }
        this.$store.dispatch(handler, {
          id: patientId,
          shipping_address: this.patient.shipping_address,
          practiceId: this.getRequestPracticeId()
        })
          .then(resp => {
            this.edit.shipping_address.edit = false;
            this.patient.shipping_address = resp.data.shipping_address;
            this.patient.phone = resp.data.shipping_address.phone;
            this.flashSuccess('You updated shipping address');
          })
          .catch(err => {
            if (err.data.errors.phone)
              this.shippingPhoneErrors = err.data.errors.phone;
            else {
              this.flashError('Error updating shipping address');
            }
          });
      }
    },

    // @vuese
    // Used to show tooltip
    // @arg HTML element data type
    hover(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.add('open');
    },

    // @vuese
    // Used to hide tooltip
    // @arg HTML element data type
    hoverOff(type){
      var query = 'div[data-tooltip-type="' + type + '"]';
      document.querySelector(query).classList.remove('open');
    },

    // @vuese
    // Used to check if billing address valid
    async billingValid() {
      return await this.$refs.billing_address.validate() &&
        this.billingPhoneErrors.length == 0;
    },
    // @vuese
    // Used to save billing address
    async saveBilling() {
      const patientId = this.$route.params.id;
      if (await this.billingValid()) {
        let handler = UPDATE_PATIENT_BILLING_ADDRESS;
        if (
          this.patient.billing_address.type &&
          this.patient.billing_address.type == 'default'
        ) {
          handler = ADD_PATIENT_BILLING_ADDRESS;
        }

        this.$store.dispatch(handler, {
          id: patientId,
          billing_address: this.patient.billing_address,
          practiceId: this.getRequestPracticeId()
        })
          .then(resp => {
            this.edit.billing_address.edit = false;
            this.patient.billing_address = resp.data.billing_address;
            this.flashSuccess('You updated billing address');
          })
          .catch(err => {
            if (err.data.errors.phone)
              this.billingPhoneErrors = err.data.errors.phone;
            else {
              this.flashError('Error updating billing address');
            }
          });
      }
    },

    // @vuese
    // Used to save patment method
    async savePayment() {
      const patientId = this.$route.params.id;
      if (this.shouldCreateToken) {
        await this.createPaymentMethod();
        //await this.createToken();
        if (this.cardError) return;
        this.saveToken(patientId)
          .then(resp => {
            this.patient.payment_method = resp.data.patient.payment_method;
            this.edit.paymentMethods.edit = false;

            this.flashSuccess('You updated payment method');
          })
          .catch(() => {
            this.flashError('Error updating payment method');
          });
      }
    },
    // @vue
    // Used to update patient data
    // @arg Type of property that we need to update `String`
    async saveInfo(type) {
      const patientId = this.$route.params.id;
      if (type == 'phone' || this.$refs[type] && await this.$refs[type].validate()) {
        let data, updating;
        switch (type) {
        case 'name':
          data = {
            first_name: this.patient.first_name,
            last_name: this.patient.last_name
          };
          updating = 'name';
          break;
        case 'physician':
          data = {
            user_id: this.patient.user_id,
          };

          if (this.isRole('super_admin'))
            data.practice_id = this.practiceId;

          updating = 'physician';
          break;
        case 'email':
          data = {
            email: this.patient.email
          };
          updating = 'email';
          break;
        case 'phone':
          data = {
            phone: this.patient.phone ? this.patient.phone : null
          };
          updating = 'phone';
          break;
        }

        let handler = UPDATE_PATIENT;
        if (this.isRole('super_admin') && updating == 'physician')
          handler = REASSIGN_PATIENT;

        this.$store.dispatch(handler, {
          id: patientId,
          patient: data,
          practiceId: this.getRequestPracticeId()
        })
          .then(resp => {
            if (type == 'physician') {
              this.patient.physician = resp.data.patient.physician;
              this.patient.practice = resp.data.patient.practice;
            }

            this.edit[type].edit = false;

            this.flashSuccess(`You updated ${updating}`);
          })
          .catch(err => {
            this.flashError(err.data.errors);
            if (err.data.errors.email)
              this.emailErrors = err.data.errors.email;
            else if (err.data.errors.phone)
              this.phoneErrors = err.data.errors.phone;
            else {
              this.flashError(`Error updating ${updating}`);
            }
          });
      }
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      const practiceId = this.patient.practice ? this.patient.practice.id : '';
      return this.isRole('super_admin') ? `/${practiceId}` : '';
    },

    // @vuese
    // Used to load Physican
    loadPhysicansWithParctice() {
      if (this.isRole('super_admin') && this.practiceId) {
        this.filters['practice_id'] = this.practiceId;
      }
      this.loadMorePhysicians({
        type: 'search',
        filters: this.filters,
      });
    },

    currentPracticeName() {
      return this.patient && this.patient.practice ? this.patient.practice.name : 'Select practice';
    },
    // @vuese
    // Used to open edit box and store current value
    // @arg Type of box that we need to open `String`
    editInfo(type) {
      this.edit[type].edit = true;
      switch (type) {
      case 'name':
        this.edit.name.first_name = this.patient.first_name;
        this.edit.name.last_name = this.patient.last_name;
        break;
      case 'physician':
        this.edit.physician.user_id = this.patient.user_id;
        this.loadPhysicansWithParctice();
        this.loadMorePractices({type: 'search'});
        break;
      case 'email':
        this.edit.email.value = this.patient.email;
        break;
      case 'phone':
        this.edit.phone.value = this.patient.phone;
        break;
      case 'shipping_address':
        this.edit.shipping_address.prevObj = {...this.patient.shipping_address};
        setTimeout(() => {
          this.initAutocomplete('street', this.shippingComponent, 'patient.shipping_address');
        })
        break;
      case 'billing_address':
        this.edit.billing_address.prevObj = {...this.patient.billing_address};
        setTimeout(() => {
          this.initAutocomplete('billing_street', this.shippingComponent, 'patient.billing_address');
        });
        break;
      case 'paymentMethods':
        this.initStripe();
        if (this.patient.payment_method)
          this.edit.paymentMethods.prevObj = {...this.patient.payment_method};
        break;
      }
    },

    // @vuese
    // Used to cancel edit box, and to check if value was changed
    // if yes then show continue without saving modal
    // @arg Type of box `String`
    cancelEdit(type) {
      switch (type) {
      case 'name':
        if (this.edit.name.first_name != this.patient.first_name || 
            this.edit.name.last_name != this.patient.last_name) {
          this.showModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      case 'physician':
        if (this.edit.physician.user_id != this.patient.user_id) {
          this.showModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      case 'email': 
        if (this.edit.email.value != this.patient.email) {
          this.showModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      case 'phone': 
        if (this.edit.phone.value != this.patient.phone) {
          this.showModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      case 'shipping_address': 
        if (!isEqual(this.edit.shipping_address.prevObj, this.patient.shipping_address)) {
          this.showModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      case 'billing_address':
        if (!isEqual(this.edit.billing_address.prevObj, this.patient.billing_address)) {
          this.showModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      case 'paymentMethods': 
        if (this.shouldCreateToken) {
          this.showModal = true;
          this.modalCheck = type;
        } else {
          this.continueWithoutSaving(type);
        }
        break;
      }
    },

    // @vuese
    // Used to restore old info close edit box
    // @arg Type of box `String`
    continueWithoutSaving(type) {
      this.showModal = false;
      switch (type) {
      case 'name':
        this.patient.first_name = this.edit.name.first_name;
        this.patient.last_name = this.edit.name.last_name;
        this.edit.name.edit = false;
        break;
      case 'physician':
        this.patient.user_id = this.edit.physician.user_id;
        this.edit.physician.edit = false;
        break;
      case 'email': 
        this.patient.email = this.edit.email.value;
        this.edit.email.edit = false;
        break;
      case 'phone': 
        this.patient.phone = this.edit.phone.value;
        this.edit.phone.edit = false;
        break;
      case 'shipping_address': 
        this.patient.shipping_address = {...this.edit.shipping_address.prevObj};
        this.edit.shipping_address.edit = false;
        break;
      case 'billing_address': 
        this.patient.billing_address = {...this.edit.billing_address.prevObj};
        this.edit.billing_address.edit = false;
        break;
      case 'paymentMethods': 
        this.edit.paymentMethods.edit = false;
        this.initStripe();
        break;
      }
    },
    // @vuese
    // Used to clear email errors, when user type something
    enterEmail() {
      this.emailErrors = [];
    },
    // @vuese
    // Used to change number and convert it
    // @arg Type of number
    changeNumber(type) {
      let errorText = 'Please enter valid phone number';
      switch (type) {
      case this.phoneTypes.patient:
        if (!this.validPhone(this.patient.phone))
          this.phoneErrors = [errorText];
        else 
          this.phoneErrors = [];
        this.patient.phone = new AsYouType('US').input(this.patient.phone);
        break;
      case this.phoneTypes.shipping:
        if (!this.validPhone(this.patient.shipping_address.phone))
          this.shippingPhoneErrors = [errorText];
        else 
          this.shippingPhoneErrors = [];
        this.patient.shipping_address.phone = new AsYouType('US').input(this.patient.shipping_address.phone);        
        break;
      case this.phoneTypes.billing:
        if (!this.validPhone(this.patient.billing_address.phone))
          this.billingPhoneErrors = [errorText];
        else 
          this.billingPhoneErrors = [];
        this.patient.billing_address.phone = new AsYouType('US').input(this.patient.billing_address.phone); 
        break;
      }
    },
    // @vuese
    // Used to enter number, validate, and convert it
    // @arg Type of number
    enterNumber(type) {
      let number = '';
      if (type == this.phoneTypes.patient) {
        number = this.patient.phone;
      } else if (type == this.phoneTypes.shipping) {
        number = this.patient.shipping_address.phone;
      } else if (type == this.phoneTypes.billing) {
        number = this.patient.billing_address.phone;
      }
      if (!this.validPhone(number)) {
        if (type == this.phoneTypes.patient) {
          this.phoneErrors = ['Please enter valid phone number'];
        } else if (type == this.phoneTypes.shipping) {
          this.shippingPhoneErrors = ['Please enter valid phone number'];
        } else if (type == this.phoneTypes.billing) {
          this.billingPhoneErrors = ['Please enter valid phone number'];
        }
      } else {
        if (type == this.phoneTypes.patient) {
          this.phoneErrors = [];
        } else if (type == this.phoneTypes.shipping) {
          this.shippingPhoneErrors = [];
        } else if (type == this.phoneTypes.billing) {
          this.billingPhoneErrors = [];
        }
      }
      number = new AsYouType('US').input(number);

      if(event.inputType && event.inputType.indexOf('deleteContent') == -1){
        if (type == this.phoneTypes.patient) {
          this.patient.phone = number;
        } else if (type == this.phoneTypes.shipping) {
          this.patient.shipping_address.phone = number;
        } else if (type == this.phoneTypes.billing) {
          this.patient.billing_address.phone = number;
        }
      }
    }
  }
};
</script>